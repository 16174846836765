import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import {
  mainWhite,
  mainRed,
  aku,
  gotham,
  navyBlue,
  transHover,
  siteWidth,
  innerWidth,
  screen,
} from "../../components/common/variables";
import Logo from "../../images/tenalphas-logo.svg";
import Arrow from "../../images/svg/right-arrow.svg";
import { Link } from "gatsby";
import { fadeIn } from "../common/animations";
import { FaSearch } from "react-icons/fa";
import { toSlug } from "../common/functions";

const Wrapper = styled.nav`
  display: none;
  @media ${screen.large} {
    display: block;
    background-color: ${mainWhite};
    position: fixed;
    max-width: ${siteWidth};
    width: 100%;
    z-index: 10;
    box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.2);
  }

  .inner-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: ${innerWidth};
    margin: 0 auto;
    @media ${screen.medium} {
      padding: 47px 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .logo {
    max-width: 172px;
    align-self: center;
    margin-right: 24px;
    @media ${screen.xLarge} {
      margin-right: 34px;
    }

    svg {
      width: 100%;
    }
  }

  .nav-list {
    display: flex;
    align-items: center;

    &__item {
      margin: 0 24px;
      @media ${screen.xLarge} {
        margin: 0 34px;
      }

      svg {
        width: 20px;
        margin-right: 3px;
        vertical-align: middle;
      }
      p {
        color: ${navyBlue};
        display: inline-block;
        font-family: ${aku};
        font-size: 1.3rem;
        transition: ${transHover};

        @media ${screen.xLarge} {
          font-size: 1.4rem;
        }
      }
      a {
        color: ${navyBlue};
        font-family: ${aku};
        font-size: 1.3rem;
        transition: ${transHover};
        @media ${screen.xLarge} {
          font-size: 1.4rem;
        }
        &:hover {
          color: ${mainRed};
        }
      }

      &--drop-down-btn {
        cursor: pointer;
        position: relative;
        .plus-minus {
          width: 10px;
          vertical-align: middle;
          pointer-events: none;
          font-family: sans-serif;
          font-weight: 900;
          margin-left: 3px;
        }

        &:hover p {
          color: ${mainRed};
        }
      }

      .submenu-list {
        animation: ${fadeIn} 0.16s ease-in-out;
        background-color: ${mainWhite};
        position: absolute;
        padding: 10px 60px 10px 28px;
        margin-top: 20px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.24);
        z-index: 2;
        width: auto;

        &__item {
          a {
            font-family: ${gotham};
            font-size: 1.1rem;
            display: block;
            margin: 10px 0;
            white-space: pre;
          }
        }
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    &__contact {
      position: relative;
      z-index: 2;
      a {
        color: ${navyBlue};
        font-family: ${aku};
        font-size: 1.3rem;
        transition: ${transHover};
        @media ${screen.xLarge} {
          font-size: 1.4rem;
        }
        &:hover {
          color: ${mainRed};
        }
      }
      svg {
        width: 20px;
        margin-right: 3px;
        vertical-align: middle;
      }

      .search-input {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: hidden;
        z-index: 1;

        input {
          transition: ${transHover};
          color: ${navyBlue};
          display: block;
          border: 0;
          border-radius: 0;
          font-family: ${gotham};
          font-size: 1.1rem;
          outline: none;
          width: 260px;
          border-bottom: 2px ${navyBlue} solid;
          padding: 5px;
          @media ${screen.xLarge} {
            width: 300px;
            padding: 8px;
          }

          ::placeholder {
            color: rgba(37, 61, 95, 0.6);
          }
        }

        &--open {
          pointer-events: all;
          input {
            transform: translateX(0);
          }
        }

        &--close {
          pointer-events: none;
          input {
            transform: translateX(300px);
          }
        }
      }
    }

    &__search-icon {
      color: ${navyBlue};
      font-size: 1.5rem;
      margin-left: 45px;
      display: ${(props) => (props.isSearchPage ? `none` : `block`)};
      transition: ${transHover};
      cursor: pointer;
      @media ${screen.xLarge} {
        margin-left: 60px;
      }
      &:hover {
        color: ${mainRed};
      }

      svg {
        vertical-align: top;
      }
    }
  }

  .active {
    color: ${mainRed} !important;
  }
`;

const MainNav = ({ phoneNumber, services, sectors }) => {
  // About Us dropdown list state
  const [dropDownAbout, setDropDownAbout] = useState(false);

  // Services dropdown list state
  const [dropDownServices, setDropDownServices] = useState(false);

  // Sectors dropdown list state
  const [dropDownSectors, setDropDownSectors] = useState(false);

  // user search input
  const [searchTerm, setSearchTerm] = useState("");

  // for hide/show search state
  const [search, setSearch] = useState(false);

  // check if user on the Search page
  const isSearchPage =
    typeof window !== "undefined" && window.location.pathname === "/search";

  // target input element for focus
  const inputEl = useRef(null);

  // hide/show input search field
  const toggleSearch = () => {
    setSearch(!search);
    if (!search) {
      // delay focusing the input field due of unnatural visual
      setTimeout(() => {
        inputEl.current.focus();
      }, 300);
    }
  };

  // hide/show About Us dropdown and hide opened dropdown Services and Sectors
  const toggleAboutDropDown = (e) => {
    setDropDownAbout(!dropDownAbout);

    if (dropDownServices) {
      setDropDownServices(false);
    }

    if (dropDownSectors) {
      setDropDownSectors(false);
    }
  };

  // hide/show Services dropdown and hide opened dropdown About Us and Sectors
  const toggleServicesDropDown = (e) => {
    setDropDownServices(!dropDownServices);

    if (dropDownAbout) {
      setDropDownAbout(false);
    }

    if (dropDownSectors) {
      setDropDownSectors(false);
    }
  };

  // hide/show Sectors dropdown and hide opened dropdown About Us and Services
  const toggleSectorsDropDown = (e) => {
    setDropDownSectors(!dropDownSectors);

    if (dropDownAbout) {
      setDropDownAbout(false);
    }

    if (dropDownServices) {
      setDropDownServices(false);
    }
  };

  // event listener for hiding the opened dropdown list if user has clicked anywhere than menu lists
  // also if the user has scrolled it will hide the opened dropdown list
  useEffect(() => {
    const detectClickedElement = (e) => {
      if (e.target.className !== "") {
        setDropDownAbout(false);
        setDropDownServices(false);
        setDropDownSectors(false);
      }
    };
    document.addEventListener("click", detectClickedElement);

    const closeDropdown = () => {
      setDropDownAbout(false);
      setDropDownServices(false);
      setDropDownSectors(false);
    };

    document.addEventListener("scroll", closeDropdown);

    return () => {
      document.removeEventListener("click", detectClickedElement);
      document.removeEventListener("scroll", closeDropdown);
    };
  }, []);

  // search handler
  const goSearch = (e) => {
    e.preventDefault();
    // only navigate to Search page if user has typed something
    if (searchTerm.length) {
      navigate(`/search`, {
        // passs the user search term to the Search page as props
        state: { term: searchTerm },
      });

      setSearchTerm("");
    }
  };

  return (
    <Wrapper isSearchPage={isSearchPage}>
      <div className="inner-wrapper">
        <ul className="nav-list">
          <div className="logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <li className="nav-list__item">
            <Link to="/portfolio" activeClassName="active">
              PORTFOLIO
            </Link>
          </li>
          <li className="nav-list__item nav-list__item--drop-down-btn">
            <p onClick={toggleAboutDropDown}>
              ABOUT US{" "}
              {dropDownAbout ? (
                <span className="plus-minus">&minus;</span>
              ) : (
                <span className="plus-minus">+</span>
              )}
            </p>
            {dropDownAbout && (
              <ul className="submenu-list">
                <li className="submenu-list__item">
                  <Link to="/about" activeClassName="active">
                    About Us
                  </Link>
                </li>
                <li className="submenu-list__item">
                  <Link to="/testimonials" activeClassName="active">
                    Testimonials
                  </Link>
                </li>
                <li className="submenu-list__item">
                  <Link to="/awards" activeClassName="active">
                    Awards
                  </Link>
                </li>
                <li className="submenu-list__item">
                  <Link to="/credentials" activeClassName="active">
                    Credentials
                  </Link>
                </li>
                <li className="submenu-list__item">
                  <Link to="/wollongong" activeClassName="active">
                    Wollongong
                  </Link>
                </li>
                <li className="submenu-list__item">
                  <Link to="/equipment/" activeClassName="active">
                    Equipment
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="nav-list__item nav-list__item--drop-down-btn">
            <p onClick={toggleServicesDropDown}>
              SERVICES{" "}
              {dropDownServices ? (
                <span className="plus-minus">&minus;</span>
              ) : (
                <span className="plus-minus">+</span>
              )}
            </p>
            {dropDownServices && (
              <ul className="submenu-list">
                {services.map((service) => (
                  <li
                    className="submenu-list__item"
                    key={service.node.data.title.text}
                  >
                    <Link
                      to={`/${toSlug(service.node.data.title.text)}`}
                      activeClassName="active"
                    >
                      {service.node.data.title.text}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li className="nav-list__item nav-list__item--drop-down-btn">
            <p onClick={toggleSectorsDropDown}>
              SECTORS{" "}
              {dropDownSectors ? (
                <span className="plus-minus">&minus;</span>
              ) : (
                <span className="plus-minus">+</span>
              )}
            </p>
            {dropDownSectors && (
              <ul className="submenu-list">
                {sectors.map((sector) => (
                  <li
                    className="submenu-list__item"
                    key={sector.node.data.title.text}
                  >
                    <Link
                      to={`/${toSlug(sector.node.data.title.text)}`}
                      activeClassName="active"
                    >
                      {sector.node.data.title.text}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
          <li className="nav-list__item">
            <Link to="/news" activeClassName="active">
              NEWS
            </Link>
          </li>
        </ul>
        <div className="search">
          <div className="search__contact">
            <form
              onSubmit={goSearch}
              className={
                search
                  ? `search-input search-input--open`
                  : `search-input search-input--close`
              }
            >
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                ref={inputEl}
              />
            </form>
            <a href={`tel:${phoneNumber}`}>
              <Arrow /> <span>LET'S TALK {phoneNumber} </span>
            </a>
          </div>
          {searchTerm.length ? (
            <div className="search__search-icon" onClick={goSearch}>
              <FaSearch />
            </div>
          ) : (
            <div className="search__search-icon" onClick={toggleSearch}>
              <FaSearch />
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default MainNav;
