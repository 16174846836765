import React from "react";
import PropTypes from "prop-types";
import "./css/normalize.css";
import "./css/global.css";
import styled from "styled-components";
import { siteWidth, mainWhite } from "./components/common/variables";
import Header from "./components/header";
import Footer from "./components/footer";

const PageContainer = styled.main`
  max-width: ${siteWidth};
  margin: 0 auto;
  background: ${mainWhite};
`;

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <PageContainer>
        <Header />
        {children}
        <Footer />
      </PageContainer>
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
