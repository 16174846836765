import React, { useState, useRef } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import {
  mainWhite,
  mainRed,
  aku,
  gotham,
  navyBlue,
  transHover,
  screen,
} from "../../components/common/variables";
import { toSlug } from "../../components/common/functions";
import Logo from "../../images/tenalphas-logo.svg";
import Arrow from "../../images/svg/right-arrow.svg";
import { Link } from "gatsby";
import { FaSearch } from "react-icons/fa";
import { MdMenu, MdClose } from "react-icons/md";
import { moveBgColor } from "../common/animations";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoVimeo,
} from "react-icons/io";
import Obfuscate from "react-obfuscate";
import ScrollLock, { TouchScrollable } from "react-scrolllock";

const Wrapper = styled.nav`
  position: fixed;
  width: 100%;
  z-index: 9;
  @media ${screen.large} {
    display: none;
  }

  .navbar {
    background: ${mainWhite};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 24px;
    padding-bottom: 22px;
    position: relative;
    z-index: 10;
    box-shadow: 0 4px 10px -5px rgba(0, 0, 0, 0.4);
    @media ${screen.small} {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media ${screen.medium} {
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.xLarge} {
      padding-left: 0;
      padding-right: 0;
    }

    &__logo {
      max-width: 152px;
      align-self: center;
      margin-right: 24px;
      @media ${screen.xLarge} {
        margin-right: 34px;
      }

      svg {
        width: 100%;
      }
    }

    &__contact {
      display: flex;
      align-items: center;

      .contact-number {
        display: none;
        @media ${screen.small} {
          display: block;
        }
        a {
          color: ${navyBlue};
          font-family: ${aku};
          font-size: 1.2rem;
          transition: ${transHover};

          svg {
            width: 15px;
            margin-right: 5px;
            vertical-align: middle;
          }
        }
      }

      .menu-icon {
        color: ${navyBlue};
        cursor: pointer;
        font-size: 2.4rem;
        align-self: center;
        margin-left: 50px;

        svg {
          display: block;
        }
      }
    }
  }

  .overlay-menu {
    position: fixed;
    top: 0;
    z-index: 7;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    transition: all 0.3s ease-in-out;
    justify-content: flex-end;
    overflow: auto;
    pointer-events: ${(props) => (props.navIsOpen ? `all` : `none`)};
    &__side-bg {
      background: rgba(0, 0, 0, 0.8);
      transform: ${(props) =>
        props.navIsOpen ? `translateX(0)` : `translateX(-100%)`};
      transition: all 0.3s ease-in-out;
      @media ${screen.small} {
        width: 25%;
      }
      @media ${screen.medium} {
        width: 50%;
      }
    }

    &__nav-container {
      background-image: ${(props) =>
        `linear-gradient(70deg, ${props.bgColour[0].colour}, ${props.bgColour[1].colour}, ${props.bgColour[2].colour}, ${props.bgColour[3].colour})`};
      background-size: 800%;
      animation: ${moveBgColor} 15s infinite alternate;
      height: auto;
      padding-top: 110px;
      padding-left: 30px;
      padding-right: 30px;
      overflow: auto;
      width: 100%;
      transform: ${(props) =>
        props.navIsOpen ? `translateX(0)` : `translateX(100%)`};
      transition: all 0.35s ease-in-out;
      @media ${screen.small} {
        width: 75%;
        padding-left: 45px;
        padding-right: 45px;
      }
      @media ${screen.medium} {
        width: 50%;
      }

      .search-bar {
        position: relative;
        display: ${(props) => (props.isSearchPage ? `none` : `block`)};

        input {
          width: 100%;
          background: none;
          border: 0;
          border-radius: 0;
          border-bottom: 1px ${mainWhite} solid;
          color: ${mainWhite};
          font-family: ${gotham};
          font-size: 1.05rem;
          padding: 4px;
          outline: none;

          ::placeholder {
            color: rgba(255, 255, 255, 0.6);
          }
        }
        &__search-icon {
          color: ${mainWhite};
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          svg {
            font-size: 1.4rem;
          }
        }
      }

      .heading {
        border-bottom: 2px ${mainWhite} solid;
        color: ${mainWhite};
        font-family: ${aku};
        font-size: 2.1rem;
        display: table;
        line-height: 1.4;
        margin: 20px 0;
        @media ${screen.small} {
          font-size: 2.7rem;
        }
      }

      .main-nav-list {
        li {
          margin-top: 15px;
          display: table;
          p {
            cursor: pointer;
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.3rem;
          }
          a {
            color: ${mainWhite};
            font-family: ${gotham};
            font-size: 1.3rem;
            line-height: 1.5;
          }

          .plus-minus {
            vertical-align: middle;
            font-size: 1.2rem;
          }
        }
      }

      .sub-nav-list {
        margin-left: 14px;
        p {
          margin: 7px 0;
          a {
            font-size: 1.25rem;
          }
        }
      }
    }

    .contact-details {
      color: ${mainWhite};
      font-size: 1.2rem;
      font-family: ${gotham};
      margin-top: 15px;

      a {
        color: ${mainWhite};
      }

      &--address {
        max-width: 400px;
      }
    }

    .social {
      display: flex;
      justify-content: space-between;
      margin: 30px 0;
      @media ${screen.small} {
        padding-right: 40px;
      }
      &__icon {
        color: ${mainWhite};
        font-size: 2.2rem;
      }
    }

    .copyright {
      color: ${mainWhite};
      padding: 0 0 30px 0;
      font-family: ${gotham};
      font-size: 0.8rem;
    }
  }

  .active {
    color: ${mainRed} !important;
  }
`;

const MobileNav = ({
  phoneNumber,
  services,
  sectors,
  backgroundColour,
  email,
  abn,
  address,
  facebookUrl,
  twitterUrl,
  linkedinUrl,
  instagramUrl,
  youtubeUrl,
  vimeoUrl,
}) => {
  // About Us dropdown list state
  const [dropDownAbout, setDropDownAbout] = useState(false);

  // Services dropdown list state
  const [dropDownServices, setDropDownServices] = useState(false);

  // Sectors dropdown list state
  const [dropDownSectors, setDropDownSectors] = useState(false);

  // user search input
  const [searchTerm, setSearchTerm] = useState("");

  // for hide/show overlay menu state
  const [navIsOpen, setNavOpen] = useState(false);

  // for dynamic year on the footer
  const year = new Date().getFullYear();

  // nav container element for scrolling back up
  const navContainer = useRef(null);

  // check if user on the Search page
  const isSearchPage =
    typeof window !== "undefined" && window.location.pathname === "/search";

  // hide/show overlay menu
  const toggleNav = () => {
    setNavOpen(!navIsOpen);
    if (!navIsOpen) {
      // always scroll back up when user close the overlay menu
      navContainer.current.scrollTo(0, 0);
    }
  };

  // hide/show About Us dropdown and hide opened dropdown Services and Sectors
  const toggleAboutDropDown = (e) => {
    setDropDownAbout(!dropDownAbout);

    if (dropDownServices) {
      setDropDownServices(false);
    }

    if (dropDownSectors) {
      setDropDownSectors(false);
    }
  };

  // hide/show Services dropdown and hide opened dropdown About Us and Sectors
  const toggleServicesDropDown = (e) => {
    setDropDownServices(!dropDownServices);

    if (dropDownAbout) {
      setDropDownAbout(false);
    }

    if (dropDownSectors) {
      setDropDownSectors(false);
    }
  };

  // hide/show Sectors dropdown and hide opened dropdown About Us and Services
  const toggleSectorsDropDown = (e) => {
    setDropDownSectors(!dropDownSectors);

    if (dropDownAbout) {
      setDropDownAbout(false);
    }

    if (dropDownServices) {
      setDropDownServices(false);
    }
  };

  // search handler
  const goSearch = (e) => {
    e.preventDefault();
    // only navigate to Search page if user has typed something
    if (searchTerm.length) {
      navigate(`/search`, {
        // passs the user search term to the Search page as props
        state: { term: searchTerm },
      });

      setSearchTerm("");
    }
  };

  return (
    <Wrapper
      bgColour={backgroundColour}
      isSearchPage={isSearchPage}
      navIsOpen={navIsOpen}
    >
      <div className="navbar">
        <div className="navbar__logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="navbar__contact">
          <p className="contact-number">
            <a href={`tel:${phoneNumber}`}>
              <Arrow /> <span>LET'S TALK {phoneNumber} </span>
            </a>
          </p>
          <span className="menu-icon" onClick={toggleNav}>
            {navIsOpen ? <MdClose /> : <MdMenu />}
          </span>
        </div>
      </div>

      <div className="overlay-menu">
        {navIsOpen && <ScrollLock />}

        <div className="overlay-menu__side-bg"></div>
        <TouchScrollable>
          <nav className="overlay-menu__nav-container" ref={navContainer}>
            <form className="search-bar" onSubmit={goSearch}>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <span className="search-bar__search-icon" onClick={goSearch}>
                <FaSearch />
              </span>
            </form>
            <h6 className="heading">MENU</h6>
            <ul className="main-nav-list">
              <li>
                <Link
                  to="/portfolio"
                  activeClassName="active"
                  onClick={toggleNav}
                >
                  Portfolio
                </Link>
              </li>
              <li>
                <p onClick={toggleAboutDropDown}>
                  About us{" "}
                  {dropDownAbout ? (
                    <span className="plus-minus">-</span>
                  ) : (
                    <span className="plus-minus">+</span>
                  )}
                </p>
                {dropDownAbout && (
                  <div className="sub-nav-list">
                    <p>
                      <Link
                        to="/about"
                        activeClassName="active"
                        onClick={toggleNav}
                      >
                        About Us
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/testimonials"
                        activeClassName="active"
                        onClick={toggleNav}
                      >
                        Testimonials
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/awards"
                        activeClassName="active"
                        onClick={toggleNav}
                      >
                        Awards
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/credentials"
                        activeClassName="active"
                        onClick={toggleNav}
                      >
                        Credentials
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/wollongong"
                        activeClassName="active"
                        onClick={toggleNav}
                      >
                        Wollongong
                      </Link>
                    </p>
                    <p>
                      <Link
                        to="/equipment/"
                        activeClassName="active"
                        onClick={toggleNav}
                      >
                        Equipment
                      </Link>
                    </p>
                  </div>
                )}
              </li>
              <li>
                <p onClick={toggleServicesDropDown}>
                  Services{" "}
                  {dropDownServices ? (
                    <span className="plus-minus">-</span>
                  ) : (
                    <span className="plus-minus">+</span>
                  )}
                </p>
                {dropDownServices && (
                  <div className="sub-nav-list">
                    {services.map((service) => (
                      <p key={service.node.data.title.text}>
                        <Link
                          to={`/${toSlug(service.node.data.title.text)}/`}
                          activeClassName="active"
                          onClick={toggleNav}
                        >
                          {" "}
                          {service.node.data.title.text}
                        </Link>
                      </p>
                    ))}
                  </div>
                )}
              </li>
              <li>
                <p onClick={toggleSectorsDropDown}>
                  Sectors{" "}
                  {dropDownSectors ? (
                    <span className="plus-minus">-</span>
                  ) : (
                    <span className="plus-minus">+</span>
                  )}
                </p>
                {dropDownSectors && (
                  <div className="sub-nav-list">
                    {sectors.map((sector) => (
                      <p key={sector.node.data.title.text}>
                        <Link
                          to={`/${toSlug(sector.node.data.title.text)}/`}
                          activeClassName="active"
                          onClick={toggleNav}
                        >
                          {" "}
                          {sector.node.data.title.text}
                        </Link>
                      </p>
                    ))}
                  </div>
                )}
              </li>
              <li>
                <Link to="/news" activeClassName="active" onClick={toggleNav}>
                  News
                </Link>
              </li>
              <li>
                <Link
                  to="/sitemap"
                  activeClassName="active"
                  onClick={toggleNav}
                >
                  Sitemap
                </Link>
              </li>
            </ul>
            <h6 className="heading">GET IN TOUCH</h6>
            <p className="contact-details">
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </p>
            <p className="contact-details contact-details--address">
              {address}
            </p>
            <p className="contact-details">
              <Obfuscate email={email} />
            </p>
            <div className="social">
              <a
                className="social__icon"
                href={facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoFacebook />
              </a>
              <a
                className="social__icon"
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoTwitter />
              </a>
              <a
                className="social__icon"
                href={linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoLinkedin />
              </a>
              <a
                className="social__icon"
                href={instagramUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoInstagram />
              </a>
              <a
                className="social__icon"
                href={youtubeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoYoutube />
              </a>
              <a
                className="social__icon"
                href={vimeoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoVimeo />
              </a>
            </div>
            <p className="copyright">
              &copy; {year} TEN ALPHAS / ABN {abn}
            </p>
          </nav>
        </TouchScrollable>
      </div>
    </Wrapper>
  );
};

export default MobileNav;
