import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import MainNav from "./main-nav";
import MobileNav from "./mobile-nav";

const Header = () => {
  // Prismic content
  const data = useStaticQuery(dataQuery);
  const phoneNumber = data.details.data.phone_number;
  const email = data.details.data.email_address;
  const address = data.details.data.address;
  const abn = data.details.data.abn;
  const facebookUrl = data.details.data.facebook_url;
  const twitterUrl = data.details.data.twitter_url;
  const linkedinUrl = data.details.data.linkedin_url;
  const instagramUrl = data.details.data.instagram_url;
  const youtubeUrl = data.details.data.youtube_url;
  const vimeoUrl = data.details.data.vimeo_url;

  // manually add service Design, Infographics and Photography as they have different template
  const allServices = [
    { node: { data: { title: { text: "Design" } }, slugs: ["design"] } },
    {
      node: {
        data: { title: { text: "Infographics" } },
        slugs: ["infographics"],
      },
    },
    {
      node: {
        data: { title: { text: "Photography" } },
        slugs: ["photography"],
      },
    },
    ...data.services.edges,
  ];

  // alphabetically sort service list to use on menu's dropdown Services
  const sortedServices = allServices.sort((a, b) =>
    a.node.data.title.text < b.node.data.title.text
      ? -1
      : a.node.data.title.text > b.node.data.title.text
      ? 1
      : 0
  );

  // alphabetically sort sector list to use on menu's dropdown Sectors
  const sortedSectors = data.sectors.edges.sort((a, b) =>
    a.node.data.title.text < b.node.data.title.text
      ? -1
      : a.node.data.title.text > b.node.data.title.text
      ? 1
      : 0
  );

  // array of colours to be used for moving/animating background colour
  const backgroundColour = data.backgroundColour.data.background_colour;

  return (
    <React.Fragment>
      <MainNav
        phoneNumber={phoneNumber}
        services={sortedServices}
        sectors={sortedSectors}
      />
      <MobileNav
        phoneNumber={phoneNumber}
        services={sortedServices}
        sectors={sortedSectors}
        backgroundColour={backgroundColour}
        email={email}
        address={address}
        abn={abn}
        facebookUrl={facebookUrl}
        twitterUrl={twitterUrl}
        linkedinUrl={linkedinUrl}
        instagramUrl={instagramUrl}
        youtubeUrl={youtubeUrl}
        vimeoUrl={vimeoUrl}
      />
    </React.Fragment>
  );
};

export default Header;

const dataQuery = graphql`
  {
    details: prismicGeneralDetails {
      data {
        email_address
        phone_number
        address
        abn
        facebook_url
        twitter_url
        linkedin_url
        instagram_url
        youtube_url
        vimeo_url
      }
    }
    services: allPrismicService {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
    sectors: allPrismicSector {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
    backgroundColour: prismicHomePage {
      data {
        background_colour {
          colour
        }
      }
    }
  }
`;
