import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import styled from "styled-components";
import ReactPlayer from "react-player";
import {
  navyBlue,
  mainRed,
  mainWhite,
  aku,
  innerWidth,
  transHover,
  gotham,
  screen,
} from "../common/variables";
import { toSlug } from "../common/functions";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoLinkedin,
  IoLogoInstagram,
  IoLogoYoutube,
  IoLogoVimeo,
} from "react-icons/io";
import Logo from "../../images/tenalphas-logo.svg";
import Obfuscate from "react-obfuscate";
import NewsletterPopup from "../newsletter-popup/toggle";
import Recaptcha from "react-recaptcha";
import useSSR from "use-ssr";

const Wrapper = styled.footer`
  background: rgba(37, 61, 95, 1);
  padding-top: 45px;
  padding-bottom: 45px;
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
  overflow: hidden;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .background-video {
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 1840px !important;
    height: 100% !important;
    transform: scale(1.32) translate(-50%, -5%);
    opacity: 0.1;
    pointer-events: none;
    @media ${screen.small} {
      width: 1890px !important;
      transform: scale(1.1) translateX(-50%);
    }
    @media ${screen.medium} {
      transform: scale(1.1) translateX(-42%);
      width: 1920px !important;
    }
    @media ${screen.large} {
      transform: scale(1.28) translateX(-42%);
    }
  }

  .logo {
    max-width: ${innerWidth};
    margin: 0 auto 20px auto;
    @media ${screen.small} {
      margin: 0 auto 60px auto;
    }

    svg {
      width: 100%;
      max-width: 170px;
      @media ${screen.small} {
        max-width: 240px;
      }
    }
  }

  .content {
    max-width: ${innerWidth};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    @media ${screen.medium} {
      flex-direction: row;
    }

    .details {
      @media ${screen.small} {
        display: flex;
        padding-right: 35px;
      }
      @media ${screen.medium} {
        width: 33.33%;
        display: block;
      }
      @media ${screen.large} {
        padding-right: 75px;
      }

      .texts-container {
        @media ${screen.small} {
          order: 2;
          align-self: flex-end;
        }

        .subscribe-popup {
          max-width: inherit;
          cursor: pointer;
          transition: ${transHover};
          text-decoration: underline;
          &:hover {
            color: ${mainRed};
          }
        }
      }

      &__heading {
        color: ${mainWhite};
        font-size: 2.2rem;
        font-family: ${aku};
        border-bottom: 2px solid ${mainWhite};
        padding-bottom: 2px;
        display: table;
        @media ${screen.small} {
          font-size: 2.8rem;
        }
        @media ${screen.medium} {
          display: table;
        }
        @media ${screen.large} {
          display: block;
        }

        &--tablet {
          margin-bottom: 20px;
          @media ${screen.small} {
            margin-bottom: 45px;
          }
          @media ${screen.medium} {
            display: none;
          }
        }

        &--desktop {
          display: none;
          @media ${screen.medium} {
            display: table;
          }
          @media ${screen.large} {
            display: block;
          }
        }
      }

      p {
        color: ${mainWhite};
        font-family: ${gotham};
        margin-top: 15px;
        font-size: 1rem;
        max-width: 270px;
        @media ${screen.small} {
          font-size: 1.1rem;
          margin-top: 25px;
        }
        a {
          color: ${mainWhite};
          transition: ${transHover};
          white-space: pre-line;
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainRed};
            }
          }
        }
      }

      &__social {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;

        .social-link {
          color: ${mainWhite};
          font-size: 1.7rem;
          transition: ${transHover};
          margin-right: 32px;
          display: inline-block;
          @media ${screen.medium} {
            font-size: 2.35rem;
            margin-bottom: 16px;
          }
          @media ${screen.large} {
            font-size: 1.7rem;
            margin-bottom: 0;
            margin-right: 24px;
          }
          @media ${screen.xLarge} {
            font-size: 1.7rem;
            margin-right: 32px;
          }
          @media ${screen.withCursor} {
            &:hover {
              color: ${mainRed};
            }
          }
        }

        &--tablet {
          margin-bottom: 30px;
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          @media ${screen.small} {
            margin-top: 30px;
            margin-bottom: 45px;
            justify-content: flex-start;
          }

          .social-link {
            font-size: 2rem;
            margin-right: 0;
            @media ${screen.small} {
              font-size: 2.35rem;
              margin-right: 40px;
            }
          }
          @media ${screen.medium} {
            display: none;
          }
        }

        &--desktop {
          display: none;
          @media ${screen.medium} {
            display: block;
          }
        }
      }

      &__map-frame {
        width: 100%;
        border: 0;
        height: 230px;
        margin-top: 25px;
        @media ${screen.small} {
          height: 280px;
          max-width: 370px;
          margin-right: 30px;
          margin-top: 0;
        }
        @media ${screen.medium} {
          height: 250px;
          margin-top: 25px;
          max-width: auto;
        }
        @media ${screen.large} {
          height: 350px;
        }
      }
    }

    .contact-form {
      @media ${screen.medium} {
        width: 33.33%;
        margin-left: 35px;
        padding-right: 35px;
      }
      @media ${screen.large} {
        padding-right: 75px;
        margin-left: 75px;
      }

      &__heading {
        color: ${mainWhite};
        font-size: 2.2rem;
        font-family: ${aku};
        border-bottom: 2px solid ${mainWhite};
        padding-bottom: 2px;
        display: table;
        @media ${screen.small} {
          font-size: 2.8rem;
        }
        @media ${screen.large} {
          display: block;
        }
      }

      form {
        input {
          color: ${mainWhite};
          width: 100%;
          padding: 11px;
          border: 1px ${mainWhite} solid;
          border-radius: 0;
          background: ${navyBlue};
          font-size: 1.1rem;
          font-family: ${gotham};
          outline: none;
          margin-top: 25px;
          line-height: 1.25;
          ::placeholder {
            color: rgba(255, 255, 255, 0.55);
          }
        }

        textarea {
          color: ${mainWhite};
          width: 100%;
          padding: 11px;
          border: 1px ${mainWhite} solid;
          border-radius: 0;
          background: ${navyBlue};
          font-size: 1.1rem;
          font-family: ${gotham};
          outline: none;
          height: 160px;
          margin-top: 25px;
          margin-bottom: 25px;
          line-height: 1.25;
          box-shadow: none;

          ::placeholder {
            color: rgba(255, 255, 255, 0.55);
          }
        }

        #submit-btn {
          border: 0;
          cursor: pointer;
          color: ${navyBlue};
          width: auto;
          background: ${mainWhite};
          padding: 10px 24px;
          transition: ${transHover};
          margin-top: 0;

          &:hover {
            background: ${mainRed};
            color: ${mainWhite};
          }
        }
      }
    }

    .explore {
      width: 33.33%;
      padding-right: 35px;
      margin-left: 35px;
      display: none;
      @media ${screen.medium} {
        display: block;
      }
      @media ${screen.large} {
        padding-right: 75px;
        margin-left: 75px;
      }

      &__heading {
        color: ${mainWhite};
        font-size: 2.2rem;
        font-family: ${aku};
        border-bottom: 2px solid ${mainWhite};
        padding-bottom: 2px;
        margin-bottom: 25px;
        @media ${screen.small} {
          font-size: 2.8rem;
          display: table;
        }
        @media ${screen.large} {
          display: block;
        }
      }

      p {
        color: ${mainWhite};
        font-family: ${gotham};
        font-size: 1.1rem;
        margin-bottom: 7px;
      }

      a {
        color: ${mainWhite};
        font-family: ${gotham};
        font-size: 1.1rem;
        transition: ${transHover};
        @media ${screen.withCursor} {
          &:hover {
            color: ${mainRed};
          }
        }
      }

      .subnav-btn {
        cursor: pointer;
        transition: ${transHover};

        &:hover {
          color: ${mainRed};
        }

        .plus-minus {
          font-family: sans-serif;
        }
      }

      .about-list,
      .services-list,
      .sectors-list {
        margin: 0 0 12px 12px;
        p {
          margin-bottom: 2px;
          a {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .active {
    color: ${mainRed} !important;
  }
`;

const FooterEnd = styled.div`
  max-width: ${innerWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    flex-direction: row;
  }
  @media ${screen.medium} {
    padding-top: 35px;
    padding-bottom: 35px;
    padding-left: 50px;
    padding-right: 50px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .copyright {
    display: flex;
    flex-wrap: wrap;
    font-family: ${gotham};

    p {
      color: ${navyBlue};
      font-size: 0.7rem;
      margin-bottom: 8px;
      @media ${screen.small} {
        margin-bottom: 0;
      }
      @media ${screen.medium} {
        font-size: 0.85rem;
      }

      a {
        color: ${navyBlue};
        transition: ${transHover};
        &:hover {
          color: ${mainRed};
        }
      }

      span {
        margin: 0 5px;
        @media ${screen.small} {
          margin: 0 10px;
        }
      }
    }

    .site-creator {
      a {
        color: ${mainRed};
      }
    }
  }
`;

const Footer = () => {
  // About Us dropdown list state
  const [aboutList, setAboutList] = useState(false);

  // Services dropdown list state
  const [serviceList, setServiceList] = useState(false);

  // Sectors dropdown list state
  const [sectorList, setSectorList] = useState(false);

  // Newsletter popup state
  const [subPopUp, setPopUp] = useState(false);

  // reCaptcha state if reCaptcha has been validated
  const { isBrowser } = useSSR();

  // Prismic content
  const data = useStaticQuery(dataQuery);
  const email = data.details.data.email_address;
  const phone = data.details.data.phone_number;
  const address = data.details.data.address;
  const abn = data.details.data.abn;
  const facebookUrl = data.details.data.facebook_url;
  const instagramUrl = data.details.data.instagram_url;
  const linkedinUrl = data.details.data.linkedin_url;
  const twitterUrl = data.details.data.twitter_url;
  const youtubeUrl = data.details.data.youtube_url;
  const vimeoUrl = data.details.data.vimeo_url;
  const backgroundVideo = data.video.data.video.document.data.video_url;

  // for dynamic year on the footer
  const year = new Date().getFullYear();

  // dynamic url to send along with the salesforce form submission

  // show newsletter form
  const openPopUp = () => {
    setPopUp(true);
  };

  // hide newsletter form
  const closePopUp = () => {
    setPopUp(false);
  };

  // alphabetically sort service list to use on footer's dropdown Services
  const sortedServices = data.services.edges.sort((a, b) =>
    a.node.data.title.text < b.node.data.title.text
      ? -1
      : a.node.data.title.text > b.node.data.title.text
      ? 1
      : 0
  );

  // alphabetically sort sector list to use on footers's dropdown Sectors
  const sortedSectors = data.sectors.edges.sort((a, b) =>
    a.node.data.title.text < b.node.data.title.text
      ? -1
      : a.node.data.title.text > b.node.data.title.text
      ? 1
      : 0
  );

  // hide/show About Us dropdown and hide opened dropdown Services and Sectors
  const toggleAboutList = () => {
    setAboutList(!aboutList);
    setServiceList(false);
    setSectorList(false);
  };

  // hide/show Services dropdown and hide opened dropdown About Us and Sectors
  const toggleServiceList = () => {
    setServiceList(!serviceList);
    setAboutList(false);
    setSectorList(false);
  };

  // hide/show Sectors dropdown and hide opened dropdown About Us and Services
  const toggleSectorList = () => {
    setSectorList(!sectorList);
    setAboutList(false);
    setServiceList(false);
  };

  useEffect(() => {
    function timestamp() {
      var response = document.getElementById("g-recaptcha-response");

      if (response == null || response.value.trim() == "") {
        var elems = JSON.parse(
          document.getElementsByName("captcha_settings")[0].value
        );
        elems["ts"] = JSON.stringify(new Date().getTime());
        document.getElementsByName(
          "captcha_settings"
        )[0].value = JSON.stringify(elems);
      }
    }
    setInterval(timestamp, 500);
  }, []);

  return (
    <React.Fragment>
      <Wrapper>
        <ReactPlayer
          className="background-video"
          url={backgroundVideo}
          loop={true}
          playing={true}
          muted
          config={{
            // disable video fullscreen
            vimeo: {
              fullscreen: 0,
            },
          }}
        />
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="content">
          <h6 className="details__heading details__heading--tablet">
            GET IN TOUCH
          </h6>
          <div className="details">
            <div className="texts-container">
              {/* footer heading for desktop */}
              <h6 className="details__heading details__heading--desktop">
                GET IN TOUCH
              </h6>
              {/* end footer heading for desktop */}

              <p>
                <a href={`tel:${phone}`}>{phone}</a>
              </p>
              <p>
                <a
                  href="https://goo.gl/maps/XQkrG5N72CPmTx3Y6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {address}
                </a>
              </p>
              <p>
                {/* hide email from bots */}
                <Obfuscate email={email} />
              </p>
              <p className="subscribe-popup" onClick={openPopUp}>
                Subscribe to receive our updates
              </p>

              {/* social media list for desktop */}
              <div className="details__social details__social--desktop">
                {facebookUrl && (
                  <a
                    className="social-link"
                    href={facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoFacebook />
                  </a>
                )}
                {twitterUrl && (
                  <a
                    className="social-link"
                    href={twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoTwitter />
                  </a>
                )}
                {linkedinUrl && (
                  <a
                    className="social-link"
                    href={linkedinUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoLinkedin />
                  </a>
                )}
                {instagramUrl && (
                  <a
                    className="social-link"
                    href={instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoInstagram />
                  </a>
                )}
                {youtubeUrl && (
                  <a
                    className="social-link"
                    href={youtubeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoYoutube />
                  </a>
                )}
                {vimeoUrl && (
                  <a
                    className="social-link"
                    href={vimeoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoLogoVimeo />
                  </a>
                )}
              </div>
              {/* end social media list for desktop */}
            </div>
            <iframe
              className="details__map-frame"
              src="https://snazzymaps.com/embed/178864"
              title="TENALPHAS Address"
            ></iframe>
          </div>

          {/* social media list for tablet and mobile */}
          <div className="details__social details__social--tablet">
            {facebookUrl && (
              <a
                className="social-link"
                href={facebookUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoFacebook />
              </a>
            )}
            {twitterUrl && (
              <a
                className="social-link"
                href={twitterUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoTwitter />
              </a>
            )}
            {linkedinUrl && (
              <a
                className="social-link"
                href={linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoLinkedin />
              </a>
            )}
            {instagramUrl && (
              <a
                className="social-link"
                href={instagramUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoInstagram />
              </a>
            )}
            {youtubeUrl && (
              <a
                className="social-link"
                href={youtubeUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoYoutube />
              </a>
            )}
            {vimeoUrl && (
              <a
                className="social-link"
                href={vimeoUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IoLogoVimeo />
              </a>
            )}
          </div>
          {/* end social media list for tablet and mobile  */}

          <div className="contact-form">
            <h6 className="contact-form__heading">CONTACT</h6>

            <form
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
              method="POST"
            >
              <input
                type="hidden"
                name="captcha_settings"
                value='{"keyname":"tenalphas_moving_content","fallback":"true","orgId":"00D90000000rKVV","ts":""}'
              />
              <input type="hidden" name="oid" value="00D90000000rKVV" />
              <input
                type="hidden"
                name="retURL"
                value="https://tenalphas.com.au/thanks/"
              />
              <input
                id="first_name"
                placeholder="First Name"
                maxLength="40"
                name="first_name"
                size="20"
                type="text"
                required
              />
              <br />
              <input
                id="last_name"
                placeholder="Last Name"
                maxLength="80"
                name="last_name"
                size="20"
                type="text"
                required
              />
              <br />
              <input
                id="email"
                placeholder="Email Address"
                maxLength="80"
                name="email"
                size="20"
                type="text"
                required
              />
              <br />
              <input
                id="company"
                placeholder="Company"
                maxLength="40"
                name="company"
                size="20"
                type="text"
              />
              <br />

              <textarea
                id="00N6F00000WyqZK"
                placeholder="Message"
                name="00N6F00000WyqZK"
                rows="3"
                type="text"
                wrap="soft"
                required
              ></textarea>
              <br />

              {isBrowser && (
                <Recaptcha sitekey="6Lf7n7wUAAAAAE6otNfjkowMdfY7mOrcUD7qCfnd" />
              )}

              <br />
              <input id="submit-btn" type="submit" name="submit" />
            </form>
          </div>

          <div className="explore">
            <h6 className="explore__heading">EXPLORE</h6>
            <p>
              <Link to="/portfolio" activeClassName="active">
                Portfolio
              </Link>
            </p>
            <p className="subnav-btn" onClick={toggleAboutList}>
              About Us{" "}
              {aboutList ? (
                <span className="plus-minus">&minus;</span>
              ) : (
                <span className="plus-minus">+</span>
              )}
            </p>
            {aboutList && (
              <div className="about-list">
                <p>
                  <Link to="/about" activeClassName="active">
                    About Us
                  </Link>
                </p>
                <p>
                  <Link to="/testimonials" activeClassName="active">
                    Testimonials
                  </Link>
                </p>
                <p>
                  <Link to="/awards" activeClassName="active">
                    Awards
                  </Link>
                </p>
                <p>
                  <Link to="/credentials" activeClassName="active">
                    Credentials
                  </Link>
                </p>
                <p>
                  <Link to="/wollongong" activeClassName="active">
                    Wollongong
                  </Link>
                </p>
                <p>
                  <Link to="/equipment/" activeClassName="active">
                    Equipment
                  </Link>
                </p>
              </div>
            )}

            <p className="subnav-btn" onClick={toggleServiceList}>
              Services{" "}
              {serviceList ? (
                <span className="plus-minus">&minus;</span>
              ) : (
                <span className="plus-minus">+</span>
              )}
            </p>
            {serviceList && (
              <div className="services-list">
                {sortedServices.map((service) => (
                  <p key={service.node.data.title.text}>
                    <Link
                      to={`/${toSlug(service.node.data.title.text)}`}
                      activeClassName="active"
                    >
                      {" "}
                      {service.node.data.title.text}
                    </Link>
                  </p>
                ))}
                <p>
                  <Link to="/design" activeClassName="active">
                    Design
                  </Link>
                </p>
                <p>
                  <Link to="/photography" activeClassName="active">
                    Photography
                  </Link>
                </p>
                <p>
                  <Link to="/infographics" activeClassName="active">
                    Infographics
                  </Link>
                </p>
              </div>
            )}
            <p className="subnav-btn" onClick={toggleSectorList}>
              Sectors{" "}
              {sectorList ? (
                <span className="plus-minus">&minus;</span>
              ) : (
                <span className="plus-minus">+</span>
              )}
            </p>
            {sectorList && (
              <div className="sectors-list">
                {sortedSectors.map((sector) => (
                  <p key={sector.node.data.title.text}>
                    <Link
                      to={`/${toSlug(sector.node.data.title.text)}`}
                      activeClassName="active"
                    >
                      {" "}
                      {sector.node.data.title.text}
                    </Link>
                  </p>
                ))}
              </div>
            )}
            <p>
              <Link to="/news" activeClassName="active">
                News
              </Link>
            </p>
            <p>
              <Link to="/sitemap" activeClassName="active">
                Sitemap
              </Link>
            </p>
          </div>
        </div>
      </Wrapper>
      <FooterEnd>
        <div className="copyright">
          <p>
            &copy; {year} TEN ALPHAS <span>/</span>
          </p>
          <p>
            ABN {abn} <span>/</span>
          </p>
          <p>
            <Link to="/terms-conditions">Terms & Conditions</Link>
            <span>/</span>
          </p>
          <p>
            <Link to="/disclaimer-copyright-privacy">Privacy Policy</Link>
            <span>/</span>
          </p>
          <p className="site-creator">
            Built by{" "}
            <a href="https://www.hellohandsome.com.au/" target="_blank">
              Handsome Creative
            </a>
          </p>
        </div>
      </FooterEnd>

      {/* manually open newsletter popup */}
      {subPopUp && <NewsletterPopup closePopUp={closePopUp} />}
    </React.Fragment>
  );
};

export default Footer;

const dataQuery = graphql`
  {
    details: prismicGeneralDetails {
      data {
        email_address
        phone_number
        address
        abn
        facebook_url
        twitter_url
        linkedin_url
        instagram_url
        youtube_url
        vimeo_url
      }
    }
    services: allPrismicService {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
    sectors: allPrismicSector {
      edges {
        node {
          data {
            title {
              text
            }
          }
        }
      }
    }
    video: prismicGeneralDetails {
      data {
        video {
          document {
            ... on PrismicProject {
              data {
                video_url
              }
            }
          }
        }
      }
    }
  }
`;
