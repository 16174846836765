// function helpers

import slugify from "slugify";

export const setHash = (val = "") => {
  window.location.hash = val;
};

export const getVimeoId = url => {
  const match = /vimeo.*\/(\d+)/i.exec(url);

  if (match) {
    return match[1];
  }
};

export const reduceWords = (str, length = 31) => {
  return (
    str
      .split(" ")
      .slice(0, length)
      .join(" ") + "..."
  );
};

export const toSlug = string => {
  if (typeof string === "string") {
    return slugify(string, {
      replacement: "-", // replace spaces with replacement character, defaults to `-`
      remove: undefined, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
    });
  }
};
