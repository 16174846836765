// Newsletter than can be toggled from the footer

import React, { useState } from "react";
import styled from "styled-components";
import addToMailchimp from "gatsby-plugin-mailchimp";
import {
  emailRegex,
  mainRed,
  mainWhite,
  transHover,
  screen,
  navyBlue,
  lightNavyBlue,
  mainBlue,
  lightBlue,
  aku,
  gotham,
} from "../common/variables";
import { slideIn, fadeIn } from "../common/animations";
import Arrow from "../../images/svg/right-arrow.svg";
import Close from "../../images/svg/close-icon.svg";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  animation: ${fadeIn} 0.2s ease-out;

  form {
    background-image: linear-gradient(
      90deg,
      ${navyBlue},
      ${lightNavyBlue},
      ${mainBlue},
      ${lightBlue}
    );
    animation: ${slideIn} 0.4s ease-out;
    max-width: 358px;
    padding: 40px 20px 50px 20px;
    margin: auto;
    position: relative;
    width: 96%;

    @media ${screen.small} {
      max-width: 800px;
      padding: 50px 40px;
    }

    @media ${screen.medium} {
      max-width: 800px;
      padding: 50px 40px;
    }

    .heading {
      color: ${mainWhite};
      font-size: 1.55rem;
      font-family: ${aku};
      @media ${screen.small} {
        font-size: 3rem;
      }
    }

    .subheading {
      font-size: 0.95rem;
      margin-top: 5px;
      @media ${screen.small} {
        font-size: 1.05rem;
      }
    }

    input {
      border-radius: 0;
      padding: 12px 9px 9px 9px;
      outline: none;
      margin-top: 15px;
      width: 100%;
      font-family: ${gotham};
      font-size: 1rem;
      background: rgba(255, 255, 255, 0.8);
      border: 0;
      @media ${screen.small} {
        padding: 14px;
        margin-top: 20px;
        width: 540px;
        font-size: 1.1rem;
      }

      ::placeholder {
        color: rgba(37, 61, 95, 0.7);
      }
    }

    button {
      background-color: ${mainWhite};
      border: 0;
      color: ${navyBlue};
      cursor: pointer;
      padding: 11px 0;
      margin-top: 14px;
      transition: ${transHover};
      width: 40%;
      outline: none;
      font-family: ${gotham};
      font-size: 1.1rem;
      text-align: center;
      @media ${screen.small} {
        padding: 14px 38px;
        margin-left: 14px;
        margin-top: 0;
        width: initial;
      }

      @media ${screen.withCursor} {
        &:hover {
          background-color: ${mainRed};
          color: ${mainWhite};
        }
      }
    }

    .success-text {
      color: ${mainWhite};
      font-family: ${gotham};
      font-size: 1.35rem;
      padding: 20px 0 10px 0;
    }

    .error-text {
      color: ${mainRed};
      font-size: 1.1rem;
      text-shadow: 1px 0 0 rgba(0, 0, 0, 0.8);
      font-family: ${gotham};
      margin-top: 20px;
      display: block;
      @media ${screen.small} {
        font-size: 1.2rem;
      }
    }

    .close {
      color: ${navyBlue};
      cursor: pointer;
      position: absolute;
      top: -16px;
      right: -8px;
      transition: ${transHover};
      background: ${mainWhite};
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      @media ${screen.medium} {
        width: 55px;
        height: 55px;
        top: -20px;
        right: -20px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainRed};
        }
      }

      svg {
        width: 17px;
        @media ${screen.small} {
          width: 20px;
        }
        .stroke {
          fill: ${navyBlue};
          transition: ${transHover};
        }
        &:hover {
          .stroke {
            fill: ${mainRed};
          }
        }
      }
    }

    .option {
      color: ${mainWhite};
      font-family: ${gotham};
      cursor: pointer;
      text-decoration: underline;
      transition: ${transHover};
      margin-top: 16px;
      vertical-align: middle;
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainRed};
        }
        &:hover svg .body {
          fill: ${mainRed};
        }
      }

      svg {
        width: 9px;
        margin-left: 10px;
        .body {
          fill: ${mainWhite};
          transition: ${transHover};
        }
      }
    }
  }
`;

const NewsletterPopup = ({ closePopUp }) => {
  // user email input state
  const [email, setEmail] = useState("");

  // hide/show error text feedback state
  const [errorFeedback, setErrorFeedback] = useState(false);

  // hide/show success text feedback state
  const [successFeedback, setSuccessFeedback] = useState(false);

  // error message state
  const [errorMsg, setErrorMsg] = useState(
    "Please enter a valid email address."
  );

  // email input handler
  const emailInput = e => {
    setEmail(e.target.value);
  };

  // submission handler
  const handleSubmit = e => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      // if email is invalid
      setErrorFeedback(true);
      setErrorMsg("Please enter a valid email address.");
      setEmail("");
    } else {
      // if email is valid, gatbsy plugin for mailchimp will handle this
      setEmail("");
      addToMailchimp(email).then(data => {
        // data.msg response has fixed message if user is already subscribed to the mailing list
        const checkEmail = data.msg.toLowerCase().split(" ");
        if (
          checkEmail.includes(email.toLowerCase()) ||
          checkEmail.includes(`"${email.toLowerCase()}"`)
        ) {
          // if email already in the list
          setErrorFeedback(true);
          setErrorMsg("Looks like you're already subscribed.");
          setEmail("");
        } else {
          // if email successfully added
          setSuccessFeedback(true);
          setErrorFeedback(false);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Wrapper>
        <form onSubmit={handleSubmit}>
          <h2 className="heading">Join the ten alphas newsletter</h2>
          {successFeedback ? (
            <p className="success-text">Thanks for signing up!</p>
          ) : (
            <div>
              <input
                placeholder="Enter your email address here"
                onChange={emailInput}
                value={email}
              />
              <button>Subscribe</button>
            </div>
          )}

          {errorFeedback && <span className="error-text">{errorMsg}</span>}
          <span className="close" onClick={closePopUp}>
            <Close />
          </span>
          {successFeedback ? (
            <p className="option" onClick={closePopUp}>
              Continue to site <Arrow />
            </p>
          ) : (
            <p className="option" onClick={closePopUp}>
              No thanks
            </p>
          )}
        </form>
      </Wrapper>
    </React.Fragment>
  );
};

export default NewsletterPopup;
